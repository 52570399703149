import { recentlyViewedStore } from './store'
import rootStore from '@vue-storefront/core/store';
import { plugin } from '@vue-storefront/core/modules/recently-viewed/store/plugin'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { isServer } from '@vue-storefront/core/helpers'

export const cacheStorage = StorageManager.init('recently-viewed')

export const RecentlyViewedModule: StorefrontModule = function ({ store }, fromCache) {
  if (fromCache?.['recently-viewed']) {
    recentlyViewedStore.state = fromCache['recently-viewed']
  }

  store.registerModule('recently-viewed', recentlyViewedStore)
  store.subscribe(plugin)

  if (!isServer) {
    setTimeout(() => {
      if (!isServer && !rootStore.getters['user/isLoggedIn']) {
        store.dispatch('recently-viewed/load')
      }
    }, 2000);
  }
}
