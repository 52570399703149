import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { wishlistStore } from './store'
import wishlistPersistPlugin from './store/wishlistPersistPlugin'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { isServer } from '@vue-storefront/core/helpers'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

export const WishlistModule: StorefrontModule = function ({ store }, fromCache) {
  StorageManager.init('wishlist')

  if (fromCache?.wishlist) {
    wishlistStore.state = fromCache.wishlist
  }

  store.registerModule('wishlist', wishlistStore)
  store.subscribe(wishlistPersistPlugin)

  if (!isServer) {
    EventBus.$on('user-after-loggedin', () => {
      store.dispatch('wishlist/loadWishlists')
    })
    EventBus.$on('user-after-logout', () => {
      store.dispatch('wishlist/clear')
    })
  }
}
