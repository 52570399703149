/**
 * List of modal names
 */
export enum ModalList {
  BonusCardAdd = 'm-modal-bonus-card-add.vue',
  Review = 'm-modal-review',
  Auth = 'm-modal-authentication',
  SizeGuide = 'm-modal-size-guide',
  TermsAndConditions = 'm-modal-terms-and-conditions',
  FeatureNotImplemented = 'm-modal-feature-not-implemented',
  LanguageSwitcher = 'm-modal-language-switcher',
  Newsletter = 'm-modal-newsletter',
  CartToGroupWishlist = 'm-cart-to-group-wishlist',
  GroupWishlistToCart = 'm-group-wishlist-to-cart',
  ChangePhoneAccount = 'm-account-change-phone',
  AddAdditionalPhone = 'm-modal-add-additional-phone',
  TranzzoWidgetPopup = 'm-tranzzo-widget-popup',
  ChangePinCode = 'm-modal-change-pin',
  PaymentCardAdd = 'm-modal-payment-card-add',
  PaymentCardEdit = 'm-modal-payment-card-save',
  OModalCheckoutCheckCode = 'o-modal-checkout-check-code',
  CartsConfirmation = 'm-modal-carts-confirmation',
  EditGroupWishlist = 'm-edit-group-wishlist',
  ShippingModal = 'm-modal-shipping',
  TermsAndConditionsUserAgreement = 'm-modal-terms-and-conditions-user-agreement',
  ShortPackageInfo = 'm-modal-short-package-info',
  CartPopupPromotion = 'm-cart-popup-promotion',
  CreateWishlist = 'm-modal-create-wishlist',
  WishlistManager = 'm-modal-wishlist-manager',
  AddChild = 'm-modal-add-child',
  CancelReasons = 'm-modal-cancel-reasons',
  AdultConfirmation = 'm-modal-adult-confirmation',
  InterestingCategories = 'o-modal-interesting-categories',
}

/**
 * List of modal components files
 */
// @ts-ignore
export const modalComponents = new Map([
  [ModalList.BonusCardAdd, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-bonus-card-add.vue')],
  [ModalList.Review, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-review.vue')],
  [ModalList.Auth, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-authentication.vue')],
  [ModalList.SizeGuide, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-size-guide.vue')],
  [ModalList.CancelReasons, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-cancel-reasons.vue')],
  [ModalList.AdultConfirmation, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-adult-confirmation.vue')],
  [ModalList.TermsAndConditions, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-terms-and-conditions.vue')],
  [ModalList.FeatureNotImplemented, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-feature-not-implemented.vue')],
  [ModalList.LanguageSwitcher, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-language-switcher.vue')],
  [ModalList.Newsletter, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-newsletter.vue')],
  [ModalList.ShippingModal, () => import(/* webpackChunkName: "vsf-modals" */ '$modules/shipping/components/shipping-modal.vue')],
  [ModalList.CartToGroupWishlist, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-cart-to-group-wishlist.vue')],
  [ModalList.GroupWishlistToCart, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-group-wishlist-to-cart.vue')],
  [ModalList.ChangePhoneAccount, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-account-change-phone.vue')],
  [ModalList.AddAdditionalPhone, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-add-additional-phone.vue')],
  [ModalList.TranzzoWidgetPopup, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-tranzzo-widget-popup.vue')],
  [ModalList.ChangePinCode, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-change-pin.vue')],
  [ModalList.PaymentCardAdd, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-payment-card-add.vue')],
  [ModalList.PaymentCardEdit, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-payment-card-edit.vue')],
  [ModalList.OModalCheckoutCheckCode, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/organisms/modals/o-modal-checkout-check-code.vue')],
  [ModalList.CartsConfirmation, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-carts-confirmation.vue')],
  [ModalList.EditGroupWishlist, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-edit-group-wishlist.vue')],
  [ModalList.TermsAndConditionsUserAgreement, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-terms-and-conditions-user-agreement.vue')],
  [ModalList.ShortPackageInfo, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-short-package-info.vue')],
  [ModalList.CartPopupPromotion, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-cart-popup-promotion.vue')],
  [ModalList.CreateWishlist, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-create-wishlist.vue')],
  [ModalList.WishlistManager, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/molecules/modals/m-modal-wishlist-manager.vue')],
  [ModalList.InterestingCategories, () => import(/* webpackChunkName: "vsf-modals" */ 'theme/components/organisms/modals/o-modal-interesting-categories.vue')]
])

/**
 * Optional payload for modal
 */
export const modalPayload = new Map([])

interface OpenModalArgs {
  name: string,
  payload?: unknown
}

export const modalStore = {
  state: {
    activeModals: [],
    loadedModals: []
  },
  getters: {
    activeModals: state => state.activeModals.map((name) => ({
      name,
      payload: modalPayload.get(name)
    })),
    loadedModals: state => state.loadedModals
  },
  mutations: {
    openModal (state, name) {
      if (!state.loadedModals.includes(name)) {
        state.loadedModals = [...state.loadedModals, name];
      }
      state.activeModals = [...state.activeModals, name];
    },
    closeModal (state, name) {
      state.activeModals = state.activeModals.filter(modal => modal !== name);
    }
  },
  actions: {
    openModal ({ commit }, { name, payload }: OpenModalArgs) {
      modalPayload.set(name, payload)
      commit('closeModal', name)
      commit('openModal', name)
    },
    closeModal ({ commit }, { name }) {
      modalPayload.delete(name)
      commit('closeModal', name)
    }
  }
}
