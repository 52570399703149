import { searchResultStore } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'

export const SearchResultModule: StorefrontModule = async function ({ store }, fromCache) {
  if (fromCache?.['search-result']) {
    searchResultStore.state = fromCache['search-result']
  }

  store.registerModule('search-result', searchResultStore)
}
