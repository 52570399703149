import * as types from '@vue-storefront/core/modules/user/store/mutation-types';
import * as currentMutationTypes from './mutation-types';
import { UserService } from '../data-resolver/UserService';
import { userHooksExecutors } from '@vue-storefront/core/modules/user/hooks';
import { onlineHelper, isServer } from '@vue-storefront/core/helpers';
import { Logger } from '@vue-storefront/core/lib/logger';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import config from 'config'
import i18n from '@vue-storefront/i18n'
import { ProductPricesState } from 'theme/store/cart/helpers/productPricesState';
import { ProductStockState } from 'theme/store/cart/helpers/productStockState';
import { groups, sendToLogs, webLoggingIdent } from 'theme/helpers/web-logging';
import queryString from 'query-string'
import { allSettled } from 'theme/helpers/allSetted';
import { emitTabEvent } from 'theme/store/cart/helpers/tabEvent';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'

const storageTracking = StorageManager.get('admitad')

const actions = {
  async startSession ({ commit, dispatch, getters }) {
    const usersCollection = StorageManager.get('user')
    const userData = await usersCollection.getItem('current-user')
    const lastUserToken = await usersCollection.getItem('current-token')
    const userFound = userData && lastUserToken

    if (isServer || getters.isLocalDataLoaded) return
    commit(types.USER_LOCAL_DATA_LOADED, true)

    if (userFound) {
      commit(types.USER_INFO_LOADED, userData)
    }

    commit(types.USER_START_SESSION)

    if (userFound) {
      commit(types.USER_TOKEN_CHANGED, { newToken: lastUserToken })
      await dispatch('sessionAfterAuthorized', {})
    } else {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({ 'user_id': '' });
      await dispatch('clearCurrentUserStorage')
      EventBus.$emit('session-after-nonauthorized')
    }

    EventBus.$emit('session-after-started')
  },
  async restoreOnTabFocus ({ commit, dispatch }) {
    try {
      const usersCollection = StorageManager.get('user')
      const userData = await usersCollection.getItem('current-user')
      let lastUserToken = await usersCollection.getItem('current-token')

      if (userData && lastUserToken) {
        commit(types.USER_TOKEN_CHANGED, { newToken: lastUserToken })
        await dispatch('sessionAfterAuthorized', { onBrowserTabFocus: true })

        lastUserToken = await usersCollection.getItem('current-token')

        if (!lastUserToken) return

        commit(types.USER_INFO_LOADED, userData)
      }
    } catch (e) {}
  },
  async clearCurrentUser ({ commit, dispatch }) {
    await allSettled([
      commit(types.USER_TOKEN_CHANGED, { newToken: '' }),
      commit(types.USER_GROUP_TOKEN_CHANGED, ''),
      commit(types.USER_GROUP_CHANGED, null),
      commit(types.USER_INFO_LOADED, null)
    ])

    await dispatch('clearCurrentUserStorage')

    const toPromise = [
      dispatch('cart/clearCartSyncing', null, { root: true }),
      dispatch('wishlist/clear', null, { root: true }),
      // dispatch('compare/clear', null, { root: true }),
      dispatch('checkout/savePersonalDetails', {}, { root: true }),
      dispatch('checkout/savePaymentDetails', {}, { root: true }),
      commit(types.USER_ORDERS_HISTORY_LOADED, null),
      commit(currentMutationTypes.USER_LAST_ORDER_CLEAR),
      dispatch('user-payment-cards/clearPaymentCardsList', {}, { root: true }),
      dispatch('bonusCard/clearBonusCard', {}, { root: true }),
      ProductPricesState.clearStorage(),
      ProductStockState.clearStorage()
    ]

    await allSettled(toPromise)
  },
  async clearCurrentUserStorage () {
    try {
      const usersCollection = StorageManager.get('user')

      usersCollection.removeItem('current-user')
      usersCollection.removeItem('current-token')
      usersCollection.removeItem('current-refresh-token')
      usersCollection.removeItem('avatar')
    } catch (e) {}
  },
  /**
   * Login user and return user profile and current token
   */
  async login ({ commit, dispatch }, {
    username,
    password,
    isNewUser,
    showLoader,
    captchaToken = '',
    clientStateSave = false
  }) {
    const showLoaderHandler = (status) => (
      showLoader && commit('ui/setLoader', status, { root: true })
    )

    showLoaderHandler(true)

    const resp = await UserService.login(username, password, captchaToken)
    userHooksExecutors.afterUserAuthorize(resp)

    if (resp.code === 200) {
      try {
        await dispatch('resetUserInvalidateLock', {}, { root: true })

        dispatch('userInfo/setIsNewUserFlag', !!isNewUser, { root: true })

        commit(types.USER_TOKEN_CHANGED, { newToken: resp.result, meta: resp.meta }) // TODO: handle the "Refresh-token" header

        const userData = await dispatch('sessionAfterAuthorized', { refresh: true, useCache: false, waitAfter: false, clientStateSave })

        if (userData) {
          window.dataLayer.push({ ecommerce: null })
          window.dataLayer.push({
            'event': 'login',
            'user_id': `${userData?.external_user_id || userData?.uas_user_id}`
          });
        }

        showLoaderHandler(false)
      } catch (err) {
        await dispatch('clearCurrentUser')

        showLoaderHandler(false)

        throw new Error(err)
      }
    } else {
      showLoaderHandler(false)
    }

    return resp
  },
  async userProfileData ({ getters, rootGetters, dispatch }) {
    if (!getters.getToken) {
      Logger.warn('No User token, user unauthorized', 'user')()
      return
    }

    const resp = await UserService.getProfile()

    const isLoggedIn = rootGetters['user/isLoggedIn']

    if (isLoggedIn && resp.resultCode === 401) {
      sendToLogs(
        groups.User,
        'userProfileData:is:401',
        { code: resp.resultCode },
        resp?.transparentId
      )

      await dispatch('user/logout', {}, { root: true })
    }

    return resp
  },
  async refreshUserProfile ({ commit, dispatch }, { resolvedFromCache, waitCart, clientStateSave }) {
    const resp = await dispatch('userProfileData')

    if (resp.resultCode !== 200) {
      sendToLogs(
        groups.User,
        'refreshUserProfile:not:200',
        { code: resp.resultCode, result: resp?.result || null },
        resp?.transparentId
      )

      return
    }

    const usersCollection = StorageManager.get('user')
    const lastUserToken = await usersCollection.getItem('current-token')

    if (!lastUserToken) return

    commit(types.USER_INFO_LOADED, resp.result)
    webLoggingIdent(resp?.result?.id, resp?.result)

    const toPromise = [
      dispatch('setUserGroup', resp.result)
    ]

    if (!resolvedFromCache) {
      EventBus.$emit('user-after-loggedin', resp.result)

      toPromise.push(
        dispatch('cart/authorize', { clientStateSave }, { root: true })
      )

      const result = allSettled(toPromise)

      if (waitCart) {
        await result
      }

      return resp
    }
  },
  /**
   * Load current user profile
   */
  async me ({ dispatch, getters }, {
    refresh = true,
    useCache = true,
    waitCart = true,
    clientStateSave = false
  } = {}) {
    if (!getters.getToken) {
      Logger.warn('No User token, user unauthorized', 'user')()
      return
    }

    let resolvedFromCache = false

    if (useCache) {
      const currentUser = await dispatch('restoreCurrentUserFromCache')

      if (currentUser) {
        resolvedFromCache = true
        Logger.log('Current user served from cache', 'user')()
      }
    }

    if (refresh) {
      return dispatch('refreshUserProfile', { resolvedFromCache, waitCart, clientStateSave })
    }
  },
  async refreshOrdersHistory ({ commit, dispatch, rootGetters }, {
    addMore = false,
    resolvedFromCache,
    pageSize = config.ordersHistory.pageSize,
    currentPage = 1,
    fromDate = null,
    toDate = null
  }) {
    const resp = await UserService.getOrdersHistory(pageSize, currentPage, fromDate, toDate)

    if (resp.code === 200) {
      if (addMore) {
        commit(currentMutationTypes.USER_ORDERS_HISTORY_ADD_ORDERS, resp.result)
      } else {
        commit(types.USER_ORDERS_HISTORY_LOADED, resp.result) // this also stores the current user to localForage
      }
      EventBus.$emit('user-after-loaded-orders', resp.result)
    } else {
      if (rootGetters['user/isLoggedIn']) {
        dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t('Orders history was not loaded'),
          action1: { label: i18n.t('OK') }
        }, { root: true })

        const result = resp.result
        Logger.log(result.errorMessage, 'userHistory')()
      }
    }

    if (!resolvedFromCache) {
      Promise.resolve(resp.code === 200 ? resp : null)
    }

    return resp
  },
  /**
   * Load user's orders history
   */
  async getOrdersHistory ({ dispatch, getters }, {
    addMore = false,
    refresh = true,
    useCache = true,
    pageSize = config.ordersHistory.pageSize,
    currentPage = 1,
    fromDate = null,
    toDate = null,
    status = 'all'
  }) {
    if (!getters.getToken) {
      Logger.debug('No User token, user unauthorized', 'user')()
      return Promise.resolve(null)
    }
    let resolvedFromCache = false

    if (useCache) {
      const ordersHistory = await dispatch('loadOrdersFromCache')

      if (ordersHistory) {
        resolvedFromCache = true
        Logger.log('Current user order history served from cache', 'user')()
      }
    }

    if (refresh) {
      const orderHistory = await dispatch('refreshOrdersHistory', {
        addMore,
        resolvedFromCache,
        pageSize,
        currentPage,
        fromDate,
        toDate,
        status
      })

      if (orderHistory.resultCode === 401) {
        sendToLogs(
          groups.User,
          'orderHistory:is:401',
          { code: orderHistory.resultCode },
          orderHistory?.transparentId
        )

        await dispatch('user/logout', { silent: true }, { root: true })

        return null
      }

      return orderHistory
    } else {
      if (!resolvedFromCache) {
        Promise.resolve(null)
      }
    }
  },
  async loadOnlineOrdersHistory ({ commit, dispatch }, {
    pageSize = config.ordersHistory.pageSize,
    currentPage = 1,
    fromDate = null,
    toDate = null,
    addMore = false
  }) {
    const resp = await UserService.loadOnlineOrdersHistory(pageSize, currentPage, fromDate, toDate)

    if (resp.code === 200) {
      if (addMore) {
        commit(currentMutationTypes.ADD_ORDERS_ONLINE, resp.result.orders)
      } else {
        commit(currentMutationTypes.SET_ORDERS_ONLINE, resp.result)
      }
    } else {
      dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t('Online orders history was not loaded'),
        action1: { label: i18n.t('OK') }
      }, { root: true })

      const result = resp.result
      Logger.log(result.errorMessage, 'userHistory')()
    }
    return resp
  },
  async loadOfflineOrdersHistory ({ commit, dispatch }, {
    pageSize = config.ordersHistory.pageSize,
    currentPage = 1,
    fromDate = null,
    toDate = null,
    addMore = false
  }) {
    const resp = await UserService.loadOfflineOrdersHistory(pageSize, currentPage, fromDate, toDate)

    if (resp.code === 200) {
      if (addMore) {
        commit(currentMutationTypes.ADD_ORDERS_OFFLINE, resp.result.orders)
      } else {
        commit(currentMutationTypes.SET_ORDERS_OFFLINE, resp.result)
      }
    } else {
      dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t('Offline orders history was not loaded'),
        action1: { label: i18n.t('OK') }
      }, { root: true })

      const result = resp.result
      Logger.log(result.errorMessage, 'userHistory')()
    }
    return resp
  },
  async loadOfflineOrderItems (_, {
    cheque_id = null
  }) {
    if (cheque_id === null) {
      Logger.info('No cheque ID to load offline order items ', 'userHistory')()
      return null
    }

    try {
      const response = await UserService.loadOfflineOrderItems(cheque_id)
      return response.result
    } catch (e) {
      Logger.error('Error while loading offline order items', 'userHistory')()
    }
  },
  cancelOrder (context, {
    orderNumber = null,
    reasonId = null
  }) {
    if (!orderNumber) {
      Logger.info('No order ID to cancel order ', 'userHistory')()
      return null
    }
    if (!reasonId) {
      Logger.info('No reason ID to cancel order ', 'userHistory')()
      return null
    }
    return UserService.cancelOrder(orderNumber, reasonId)
  },
  async getLastOrder ({ commit, dispatch }, {
    pageSize = 1,
    currentPage = 1,
    fromDate = null,
    toDate = null,
    silent = false
  }) {
    const resp = await UserService.loadOnlineOrdersHistory(pageSize, currentPage, fromDate, toDate)
    if (resp.code === 200) {
      const orders = resp.result?.orders || []

      const items = orders.length ? orders[0] : null
      commit(currentMutationTypes.USER_LAST_ORDER_LOADED, items)
    } else {
      if (!silent) {
        dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t('Last order was not loaded'),
          action1: { label: i18n.t('OK') }
        }, { root: true })
      }

      const result = resp.result
      Logger.log(result.errorMessage, 'lastOrder')()
    }
    return resp
  },
  async sessionAfterAuthorized ({ dispatch, getters }, {
    refresh = onlineHelper.isOnline,
    useCache = true,
    waitAfter = true,
    clientStateSave = false,
    onBrowserTabFocus = false
  }) {
    Logger.info('User session authorised ', 'user')()

    const me = await dispatch('me', { refresh, useCache, waitCart: waitAfter, clientStateSave })

    const toPromiseAfter = [
      dispatch('userInfo/validate', getters.currentUser?.phone, { root: true }),
      // dispatch('getLastOrder', { pageSize: 1, currentPage: 1, status: 'online', silent: true }),
      dispatch('userInfo/getAvatar', {}, { root: true }),
      dispatch('user-communication/loadCommunications', { skipLoader: onBrowserTabFocus }, { root: true }),
      dispatch('trackingSync'),
      dispatch('recently-viewed/syncRecentlyViewed', {}, { root: true })
    ]

    const result = allSettled(toPromiseAfter)

    if (waitAfter) {
      await result
    }

    return me?.result || null
  },
  async trackingCheckQuery () {
    try {
      if (isServer) return

      const path = queryString.parseUrl(window.location.href)

      const query = { ...path.query }
      const queryKeys = Object.keys(query)

      if (!queryKeys.includes('utm_source') && !queryKeys.includes('gclid') && !queryKeys.includes('fbclid')) {
        return
      }

      const now = Math.floor(new Date().getTime() / 1000)

      const google = queryKeys.includes('gclid') ? 'google' : null

      if (google) delete query.gclid

      const facebook = queryKeys.includes('fbclid') ? 'facebook' : null

      if (facebook) delete query.fbclid

      const toSave = {
        received_at: now,
        utm_source: query?.utm_source || google || facebook
      }

      if (query.admitad_uid) {
        toSave['admitad_uid'] = query.admitad_uid
        delete query.utm_source
        delete query.admitad_uid
      }

      await storageTracking.setItem('query', toSave)

      if (query.utm_source === 'admitad') {
        const urlQuery = queryString.stringify(query)
        const url = path.url + (urlQuery ? `?${urlQuery}` : '')
        window.history.replaceState({}, null, url);
      }
    } catch (e) {
      Logger.warn('Can\'t check request query for admitad', 'tracking')()
    }
  },
  async logout ({ commit, dispatch }, {
    silent = false,
    keepCart = true,
    emit = true,
    sync = true
  }) {
    try {
      commit(types.USER_END_SESSION)

      if (emit) emitTabEvent(true, 'userLogout')

      await dispatch('clearCurrentUser')
      await dispatch('cart/disconnect', { emit: false }, { root: true })

      EventBus.$emit('user-after-logout')
      // clear cart without sync, because after logout we don't want to clear cart on backend
      // user should have items when he comes back

      if (!silent) {
        await dispatch('notification/spawnNotification', {
          type: 'account',
          message: i18n.t("You're logged out")
          // action1: { label: i18n.t('OK') }
        }, { root: true })
      }

      if (!keepCart) {
        await dispatch('cart/clear', { sync: false }, { root: true })
      } else {
        await dispatch('cart/clearServerId', null, { root: true })
      }

      userHooksExecutors.afterUserUnauthorize()

      sync && setTimeout(() => dispatch('cart/sync', { keepCart: true }, { root: true }), 0)
    } catch (e) {}
  },
  async trackingSync () {
    try {
      const item = await storageTracking.getItem('query')

      if (!item) {
        return false
      }

      const now = Math.floor(new Date().getTime() / 1000)
      const date = now - 2592000 // 30 days

      if (!item.received_at || date > item.received_at) {
        await storageTracking.removeItem('query')
        return false
      }

      const tracking: any = {
        receivedAt: item.received_at,
        provider: item.utm_source || 'admitad'
      }

      if (item.admitad_uid) {
        tracking.trackingId = item.admitad_uid
      }

      await UserService.trackingSave(tracking)

      await storageTracking.removeItem('query')

      return true
    } catch (e) {
      Logger.warn('Can\'t save admitad hash', 'tracking')()
    }
  }
}
export default actions
