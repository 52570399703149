import { Logger } from '@vue-storefront/core/lib/logger'
import { SearchQuery } from 'storefront-query-builder';
import { BannerService } from '$modules/banner/data-resolver/BannerService';
import { setRequestCacheTags, applyFilterFromToByDatetime } from '$modules/banner/helpers';
import { checkIfDateExpired } from 'theme/helpers/date';

export default interface PromotedOffersState {
  banners: {
    mainBanners: any[],
    smallBanners: any[],
    productBanners: any[],
    menuAsideBanners: any[]
  },
  productPromotionBanners: any[],
  headImage: any[],
  informImage: any[],
  menuBanners: any[],
  preHeaderBanners: any[],
  varusCafeBanners: any[],
  brandBanners: any[]
}

export const promotedStore = {
  namespaced: true,
  state: {
    productPromotionBanners: [],
    headImage: [],
    informImage: [],
    menuBanners: [],
    preHeaderBanners: [],
    varusCafeBanners: [],
    brandBanners: []
  },
  getters: {
    getHeadImage: state => state.headImage,
    getBannersTop: state => state.informImage.filter(e => e.position === '0'),
    getBannersMiddle: state => state.informImage.filter(e => e.position === '1'),
    getMenuBanners: state => state.menuBanners,
    getProductPromotionBanners: state => state.productPromotionBanners,
    getVarusCafeBanners: state => state.varusCafeBanners,
    getPreHeaderBanners: state => state.preHeaderBanners,
    getBrandBanners: state => state.brandBanners
  },
  actions: {
    async updateHeadImage ({ commit }) {
      try {
        const query = new SearchQuery();
        applyFilterFromToByDatetime(query)
        query.applyFilter({ key: 'category_ids', value: { 'neq': null } })
        query.applyFilter({ key: 'status', value: { 'eq': '1' } })
        const { items } = await BannerService.getBanners({ query })
        const filteredItems = items.filter(item => !checkIfDateExpired(item?.datetime_to))
        setRequestCacheTags(filteredItems)
        commit('SET_HEAD_IMAGE', filteredItems)
        return filteredItems
      } catch (err) {
        Logger.debug('Unable to load headImage' + err)()
      }
    },
    async updateBannerInform ({ commit }, data) {
      try {
        if (!data?.categoryId) {
          commit('SET_INFORM_IMAGE', [])
          return
        }

        const query = new SearchQuery();
        applyFilterFromToByDatetime(query)
        query.applyFilter({ key: 'category_ids', value: { 'in': data.categoryId } })
        query.applyFilter({ key: 'status', value: { 'eq': '1' } })
        const { items } = await BannerService.getBanners({ query })
        const filteredItems = items.filter(item => !checkIfDateExpired(item?.datetime_to))
        setRequestCacheTags(filteredItems)
        commit('SET_INFORM_IMAGE', filteredItems)
      } catch (err) {
        Logger.debug('Unable to load informImage' + err)()
      }
    },
    async updateMenuBanners ({ commit }) {
      try {
        const query = new SearchQuery();
        applyFilterFromToByDatetime(query)
        query.applyFilter({ key: 'status', value: { 'eq': '1' } })
        query.applyFilter({ key: 'position', value: { 'eq': '3' } })
        const { items } = await BannerService.getBanners({ query })
        const filteredItems = items.filter(item => !checkIfDateExpired(item?.datetime_to))
        setRequestCacheTags(filteredItems)
        commit('SET_MENU_BANNERS', filteredItems)
      } catch (err) {
        Logger.debug('Unable to load menuBanners' + err)()
      }
    },
    async updatePreHeaderBanners ({ commit }) {
      try {
        const query = new SearchQuery();
        applyFilterFromToByDatetime(query)
        query.applyFilter({ key: 'status', value: { 'eq': '1' } })
        query.applyFilter({ key: 'position', value: { 'eq': '5' } })
        const { items } = await BannerService.getBanners({ query })
        const filteredItems = items.filter(item => !checkIfDateExpired(item?.datetime_to))
        setRequestCacheTags(filteredItems)
        commit('SET_PREHEADER_BANNERS', filteredItems)
      } catch (err) {
        Logger.debug('Unable to load preHeaderBanners' + err)()
      }
    },
    async updateProductPromotionBanners ({ commit, getters }, { promotionBannerIds }) {
      if (!promotionBannerIds) return;

      const banners = (getters.getProductPromotionBanners || []).map(i => `${i.id}`)
      const hasDiff = !!(banners.filter(x => !promotionBannerIds.includes(x))
        .concat(promotionBannerIds.filter(x => !banners.includes(x)))).length

      if (!hasDiff) return

      try {
        const query = new SearchQuery();
        applyFilterFromToByDatetime(query)
        query.applyFilter({ key: 'id', value: { 'in': promotionBannerIds } })
        const { items } = await BannerService.getBanners({ query })
        const filteredItems = items.filter(item => !checkIfDateExpired(item?.datetime_to))
        setRequestCacheTags(filteredItems)
        commit('SET_PRODUCT_PROMOTION_BANNERS', filteredItems)
      } catch (err) {
        Logger.debug('Unable to load varusCafeBanners' + err)()
      }
    },
    async updateVarusCafeBanners ({ commit }) {
      try {
        const query = new SearchQuery();
        applyFilterFromToByDatetime(query)
        query.applyFilter({ key: 'status', value: { 'eq': '1' } })
        query.applyFilter({ key: 'position', value: { 'eq': '4' } })
        const { items } = await BannerService.getBanners({ query })
        const filteredItems = items.filter(item => !checkIfDateExpired(item?.datetime_to))
        setRequestCacheTags(filteredItems)
        commit('SET_VARUS_CAFE_BANNERS', filteredItems)
      } catch (err) {
        Logger.debug('Unable to load varusCafeBanners' + err)()
      }
    },
    async updateBrandBanners ({ commit, dispatch }) {
      try {
        const brandCategoryId = parseInt(await dispatch('config-varus/get', { path: 'categories_map_brand_category_id' }, { root: true }))
        const query = new SearchQuery();
        applyFilterFromToByDatetime(query)
        query.applyFilter({ key: 'category_ids', value: { 'in': brandCategoryId } })

        const { items } = await BannerService.getBanners({ query })
        const filteredItems = items.filter(item => !checkIfDateExpired(item?.datetime_to))
        setRequestCacheTags(filteredItems)

        commit('SET_BRAND_BANNERS', filteredItems)
      } catch (err) {
        Logger.debug('Unable to load brandBanners' + err)()
      }
    }
  },
  mutations: {
    SET_HEAD_IMAGE (state, headImage) {
      state.headImage = headImage
    },
    SET_INFORM_IMAGE (state, image) {
      state.informImage = image || []
    },
    SET_MENU_BANNERS (state, banners) {
      state.menuBanners = banners
    },
    SET_PREHEADER_BANNERS (state, banners) {
      state.preHeaderBanners = banners
    },
    SET_PRODUCT_PROMOTION_BANNERS (state, banners) {
      state.productPromotionBanners = banners || []
    },
    SET_VARUS_CAFE_BANNERS (state, banners) {
      state.varusCafeBanners = banners
    },
    SET_BRAND_BANNERS (state, banners) {
      state.brandBanners = banners
    }
  }
}
