import { TendersStore } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { getSearchAdapter } from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory';

export const TendersModule: StorefrontModule = async function ({ store }, fromCache) {
  if (fromCache?.tenders) {
    TendersStore.state = fromCache.tenders
  }

  store.registerModule('tenders', TendersStore)

  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('tender', {
      queryProcessor: (query) => {
        return query
      },
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'tender', start, size)
      }
    });
  });
}
