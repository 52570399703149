import { userPaymentCardsStore } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'

export const UserPaymentCardsModule: StorefrontModule = async function ({ store }, fromCache) {
  if (fromCache?.['user-payment-cards']) {
    userPaymentCardsStore.state = fromCache['user-payment-cards']
  }

  store.registerModule('user-payment-cards', userPaymentCardsStore)
}
