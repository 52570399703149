import { ReviewStore } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { getSearchAdapter } from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory';

export const ProductReviewModule: StorefrontModule = async function ({ store }, fromCache) {
  if (fromCache?.['product-review']) {
    ReviewStore.state = fromCache['product-review']
  }

  store.registerModule('product-review', ReviewStore)

  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('aw_review', {
      queryProcessor: (query) => {
        return query
      },
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'aw_review', start, size)
      }
    });
  });
}
