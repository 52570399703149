import { StorefrontModule } from '@vue-storefront/core/lib/modules';

const cardIcon = `<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.6663 5.16667V11.8333C13.6663 12.0101 13.5961 12.1797 13.4711 12.3047C13.3461 12.4298 13.1765 12.5 12.9997 12.5H0.999674C0.822863 12.5 0.653294 12.4298 0.52827 12.3047C0.403246 12.1797 0.333008 12.0101 0.333008 11.8333V5.16667H13.6663ZM13.6663 3.83333H0.333008V1.16667C0.333008 0.989856 0.403246 0.820286 0.52827 0.695262C0.653294 0.570238 0.822863 0.5 0.999674 0.5H12.9997C13.1765 0.5 13.3461 0.570238 13.4711 0.695262C13.5961 0.820286 13.6663 0.989856 13.6663 1.16667V3.83333ZM8.99967 9.16667V10.5H11.6663V9.16667H8.99967Z" fill="#EB6747"/>
</svg>`

const cashIcon = `<svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.0468 12.9999C13.2259 12.9999 13.3978 12.9288 13.5243 12.8021C13.651 12.6755 13.7221 12.5037 13.7221 12.3246V9.74991H9.85823C9.63802 9.74991 9.42668 9.66237 9.27097 9.50665C9.11524 9.35092 9.02771 9.13957 9.02771 8.91938V6.9694C9.02771 6.74919 9.11524 6.53784 9.27097 6.38213C9.4267 6.22641 9.63804 6.13887 9.85823 6.13887H13.7221V3.56774C13.7221 3.38831 13.651 3.21614 13.5244 3.08893C13.3981 2.96174 13.2262 2.88984 13.0468 2.88887H10.2013L7.31244 0L4.42357 2.88887H0.678859C0.498788 2.88887 0.326133 2.96044 0.198771 3.08764C0.0715781 3.21499 0 3.38765 0 3.56773V12.3247C0.000967275 12.5041 0.0728655 12.676 0.200062 12.8024C0.327255 12.9289 0.499427 13 0.678871 13L13.0468 12.9999ZM1.44429 4.6944H12.2775C12.477 4.6944 12.6386 4.85609 12.6386 5.0555C12.6386 5.25492 12.477 5.41661 12.2775 5.41661H1.44429C1.24487 5.41661 1.08318 5.25492 1.08318 5.0555C1.08318 4.85609 1.24487 4.6944 1.44429 4.6944Z" fill="#EB6747"/>
<path d="M10.2194 6.86091H13.975C14.2342 6.86091 14.4444 7.12013 14.4444 7.33035V8.55812C14.4444 8.81734 14.2342 9.02756 13.975 9.02756H10.2194C9.96022 9.02756 9.75 8.76834 9.75 8.55812V7.33035C9.75 7.07113 9.96022 6.86091 10.2194 6.86091Z" fill="#EB6747"/>
</svg>`

const cryptoIcon = `<svg width="18" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="m6.125 7.144 3.302-3.302 3.303 3.303 1.92-1.92L9.428 0 4.204 5.223l1.921 1.92ZM4.769 8.5 2.848 6.579.927 8.499l1.92 1.922L4.77 8.5ZM6.125 9.856l3.302 3.302 3.303-3.303 1.922 1.92h-.001L9.427 17 4.2 11.775l1.924-1.919ZM16.006 10.422l1.92-1.921-1.92-1.921-1.921 1.92 1.92 1.922Z" fill="#F3BA2F"/>
  <path d="m11.375 8.499-1.948-1.95-1.95 1.95.002.004 1.948 1.947 1.949-1.949v-.002Z" fill="#F3BA2F"/>
</svg>`

export const PaymentBonusesAvailable = ['NOVA_POST_CASH_ON_DELIVERY', 'CARD_ONLINE', 'HOLD']

export const PaymentModule: StorefrontModule = function ({ store }) {
  const paymentMethodsConfig = [
    {
      'title': 'Card online',
      'code': 'CARD_ONLINE',
      'cost': 0,
      'costInclTax': 0,
      'default': false,
      'offline': true,
      'is_server_method': false,
      'message': 'STEP_PAYMENT_CARD_MESSAGE',
      'icon': cardIcon,
      'position': 3
    },
    {
      'title': 'Payment on receipt',
      'code': 'CASH_CARD',
      'cost': 0,
      'costInclTax': 0,
      'default': false,
      'offline': true,
      'is_server_method': false,
      'icon': cashIcon,
      'position': 4
    },
    {
      'title': 'Cash on delivery',
      'code': 'DELIVERY_CASH',
      'cost': 0,
      'costInclTax': 0,
      'default': false,
      'offline': true,
      'is_server_method': false,
      'icon': cashIcon,
      'position': 5
    },
    {
      'title': 'Card on delivery',
      'code': 'DELIVERY_CARD',
      'cost': 0,
      'costInclTax': 0,
      'default': false,
      'offline': true,
      'is_server_method': false,
      'icon': cardIcon,
      'position': 6
    },
    {
      'title': 'Cryptocurrency online after assembling the order',
      'code': 'CRYPTO_ONLINE',
      'cost': 0,
      'costInclTax': 0,
      'default': false,
      'offline': true,
      'is_server_method': false,
      'message': 'STEP_PAYMENT_CRYPTO_MESSAGE',
      'icon': cryptoIcon,
      'position': 1
    },
    {
      'title': 'By card when placing an order',
      'code': 'HOLD',
      'message': 'STEP_PAYMENT_HOLD_MESSAGE',
      'cost': 0,
      'costInclTax': 0,
      'default': false,
      'offline': true,
      'is_server_method': false,
      'icon': cardIcon,
      'position': 2
    },
    {
      'title': 'Cash on delivery NP',
      'code': 'NOVA_POST_CASH_ON_DELIVERY',
      'cost': 0,
      'costInclTax': 0,
      'default': false,
      'offline': true,
      'is_server_method': false,
      'message': 'STEP_PAYMENT_CASH_NOVA_MESSAGE',
      'icon': cashIcon,
      'position': 7
    }
  ]

  paymentMethodsConfig.forEach(config => {
    store.dispatch('checkout/addPaymentMethod', config)
  })
}
