import { newsletterStore } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'

export const NewsletterModule: StorefrontModule = function ({ store }, fromCache) {
  StorageManager.init('newsletter')

  if (fromCache?.newsletter) {
    newsletterStore.state = fromCache?.newsletter
  }

  store.registerModule('newsletter', newsletterStore)
}
