import { userCommunicationStore } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'

export const UserCommunicationModule: StorefrontModule = async function ({ store }, fromCache) {
  if (fromCache?.['user-communication']) {
    userCommunicationStore.state = fromCache['user-communication']
  }

  store.registerModule('user-communication', userCommunicationStore)
}
